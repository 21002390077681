@import '@/styles/common.scss';

/deep/ .varDlg .el-dialog__body {
  color: white;
  height: 90vh;
  overflow: auto;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
